<template>
    <div class="mainWrapper pb-0">
            <section class="blockElement space partnershipPage">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-12 text-center">
                            <div class="transparentBG">
                                <h1 class="bold mb-md-3">{{$t('about.text55')}}<span class="secondary letterSpace">{{$t('about.text146')}}</span></h1>
                                <h3>{{$t('about.text147')}}</h3>
                            </div>
                            <div class="text-center mt-4">
                                <a href="mailto:partnerships@zulutrade.com" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text148')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space bg-secondary">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-9 text-center">
                            <div class="boxed">
                                <h2 class="mb-md-4">{{$t('about.text149')}}</h2>
                                <p>{{$t('about.text150')}}</p>
                                <p>{{$t('about.text151')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space pb-3"> 
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-12 col-md-3">
                            <div class="card-body numaricPlan">
                                <div class="gradientSecImg">
                                    <v-lazy-image width="80" height="80" src="/assets/images/icon/investor1.svg" :alt="$t('about.text458')" :title="$t('about.text458')" />     
                                </div>
                                <p>{{$t('about.text458')}}</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="card-body">
                                <div class="gradientSecImg">
                                    <v-lazy-image width="80" height="80" src="/assets/images/icon/investor2.svg" :alt="$t('about.text459')" :title="$t('about.text459')" />  
                                </div>
                                <p>{{$t('about.text459')}}<br> {{$t('about.text459a')}} </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="card-body numaricPlan">
                                <div class="gradientSecImg">
                                    <v-lazy-image width="80" height="80" src="/assets/images/icon/investor3.svg" :alt="$t('about.text460')" :title="$t('about.text460')" /> 
                                </div>
                                <p>{{$t('about.text460')}} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            <section class="blockElement space">
                <div class="container">
                    <div class="row">
                        <div class="col-12 mb-4 mb-md-5 text-center">
                            <h2>{{$t('about.text152')}}</h2>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-4">
                            <div class="card-body text-center bg-white p-3 h-100">
                                <span class="d-block mb-4"><v-lazy-image class="d-block mx-auto h-70" src="/assets/images/traders-join-zulutrade.svg" :alt="$t('about.text395')" :title="$t('about.text396')" /></span>
                                <h4 class="bold">{{$t('about.text153')}}</h4>  
                                <p>{{$t('about.text154')}}</p>     
                            </div> 
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-4">
                            <div class="card-body text-center bg-white p-3 h-100">
                                <span class="d-block mb-4"><v-lazy-image class="d-block mx-auto h-70" src="/assets/images/ranks-traders.svg" :alt="$t('about.text397')" :title="$t('about.text398')" /></span>
                                <h4 class="bold">{{$t('about.text155')}}</h4>  
                                <p>{{$t('about.text156')}}</p>     
                            </div> 
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-4">
                            <div class="card-body text-center bg-white p-3 h-100">
                                <span class="d-block mb-4"><v-lazy-image class="d-block mx-auto h-70" src="/assets/images/investors-select.svg" :alt="$t('about.text399')" :title="$t('about.text400')" /></span>
                                <h4 class="bold">{{$t('about.text157')}}</h4>  
                                <p>{{$t('about.text158')}}</p>     
                            </div> 
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-4">
                            <div class="card-body text-center bg-white p-3 h-100">
                                <span class="d-block mb-4"><v-lazy-image class="d-block mx-auto h-70" src="/assets/images/traders-are-rewarded.svg" :alt="$t('about.text401')" :title="$t('about.text402')" /></span>
                                <h4 class="bold">{{$t('about.text159')}}</h4>  
                                <p>{{$t('about.text160')}}</p>     
                            </div> 
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space darkZulutrade">
                <div class="container">
                    <div class="row row-cols-5 whyZuluRow justify-content-center justify-content-md-start">
                        <div class="col-12 mb-4 mb-md-4 text-center">
                            <h2 class="text-white">{{$t('about.text161')}}</h2>
                        </div>
                        <div class="col col1 mb-4 mb-md-0">
                            <div class="item-icon text-center">
                                <v-lazy-image src="/assets/images/higher-retention.svg" :alt="$t('about.text403')" />
                                <span class="text-white">{{$t('about.text162')}}</span>
                            </div>
                        </div>
                        <div class="col col2 mb-4 mb-md-0">
                            <div class="item-icon text-center">
                                <v-lazy-image src="/assets/images/tailor-made.svg" :alt="$t('about.text404')" />
                                <span class="text-white">{{$t('about.text163')}}</span>
                            </div>
                        </div>
                        <div class="col col3 mb-4 mb-md-0">
                            <div class="item-icon text-center">
                                <v-lazy-image src="/assets/images/no-fees.svg" :alt="$t('about.text405')" />
                                <span class="text-white">{{$t('about.text164')}}</span>
                            </div>
                        </div>
                        <div class="col col4 mb-4 mb-md-0">
                            <div class="item-icon text-center">
                                <v-lazy-image src="/assets/images/multiple-platforms.svg" :alt="$t('about.text406')" />
                                <span class="text-white">{{$t('about.text165')}}</span>
                            </div>
                        </div>
                        <div class="col col5 mb-4 mb-md-0">
                            <div class="item-icon text-center">
                                <v-lazy-image src="/assets/images/brand-name.svg" :alt="$t('about.text407')" />
                                <span class="text-white">{{$t('about.text166')}}</span>
                            </div>
                        </div>
                        <div class="col col6 mb-4 mb-md-0">
                            <div class="item-icon text-center">
                                <v-lazy-image src="/assets/images/intergration.svg" :alt="$t('about.text408')" />
                                <span class="text-white">{{$t('about.text167')}}</span>
                            </div>
                        </div>
                        <div class="col col7 mb-4 mb-md-0">
                            <div class="item-icon text-center">
                                <v-lazy-image src="/assets/images/regulation.svg" :alt="$t('about.text409')" />
                                <span class="text-white">{{$t('about.text168')}}</span>
                            </div>
                        </div>
                        <div class="col col8 mb-4 mb-md-0">
                            <div class="item-icon text-center">
                                <v-lazy-image src="/assets/images/247-support.svg" :alt="$t('about.text410')" />
                                <span class="text-white">{{$t('about.text169')}}</span>
                            </div>
                        </div>
                        <div class="col col9 mb-4 mb-md-0">
                            <div class="item-icon text-center">
                                <v-lazy-image src="/assets/images/quick-set-up.svg" :alt="$t('about.text411')" />
                                <span class="text-white">{{$t('about.text170')}}</span>
                            </div>
                        </div>
                        <div class="col col10 mb-4 mb-md-0">
                            <div class="item-icon text-center">
                                <v-lazy-image src="/assets/images/multi-asset-platform.svg" :alt="$t('about.text412')" />
                                <span class="text-white">{{$t('about.text171')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space bg-white">
                <div class="container">
                    <div class="row flex-row-reverse">
                        <div class="col-12 mb-4 mb-md-4 text-center">
                            <h2>{{$t('about.text172')}}</h2>
                            <p>{{$t('about.text173')}}</p>
                        </div>
                        <div class="col-12 col-lg-6">
                            <v-lazy-image class="d-block mx-auto" src="/assets/images/standard-whitelabel.png" :alt="$t('about.text413')" :title="$t('about.text414')" />
                        </div>
                        <div class="col-12 col-lg-6">
                            <p class="secondary">{{$t('about.text174')}}</p>
                            <h6>{{$t('about.text175')}}</h6>
                            <ul class="listArrow">
                                <li>{{$t('about.text176')}}</li>
                                <li>{{$t('about.text177')}}</li>
                                <li>{{$t('about.text178')}}</li>
                                <!-- <li>User-friendly with Unique risk management features such as Automator, ZuluGuard, ZuluRank, Simulation and numerous unique auto-trading customization settings.</li> -->
                                <li>{{$t('about.text179')}}</li>
                                <li>{{$t('about.text180')}}</li>
                                <li>{{$t('about.text181')}}</li>
                                <!-- <li>{{$t('about.text182')}}</li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <!-- <section class="blockElement space">
                <div class="container">
                    <div class="row flex-row-reverse">
                        <div class="col-12 mb-4 mb-md-4 text-center">
                            <h2>Our Systems</h2>
                        </div>
                        <div class="col-12 col-lg-6">
                            <v-lazy-image class="d-block mx-auto" src="/assets/images/ztpLarge.png" alt="Partner with ZuluTrade | ZuluTrade Social Trading 23" title="The standard White Label" />
                        </div>
                        <div class="col-12 col-lg-6">
                            <h5>ZTP PLATFORM</h5>
                            <p class="secondary">ZTP Trading Platform is ZuluTrade's innovative proprietary platform.</p>
                            <h6>Benefits:</h6>
                            <ul class="listArrow">
                                <li>Full API support.</li>
                                <li>Plug and play support of major liquidity providers.</li>
                                <li>Fully fledged back office, risk management and admin tools.</li>
                                <li>IBs supported.</li>
                                <li>User oriented design.</li>
                                <li>Powerfull Reporting tools.</li>
                                <li>Localization. 26 supported languages.</li>
                                <li>Unique auto trading features.</li>
                                <li>Create and deploy Zulu scripts from your account.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> -->
            
            <section class="blockElement space bg-white additionalBox">
                <div class="container">
                    <div class="row">
                        <div class="col-12 mb-4 mb-md-5 text-center">
                            <h2>{{$t('about.text183')}}</h2>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body text-center bg-white p-3 h-100">
                                <span class="d-block mb-4"><v-lazy-image class="d-block mx-auto h-70" src="/assets/images/liquidityIcon.svg" :alt="$t('about.text415')" :title="$t('about.text416')" /></span>
                                <h4 class="bold">{{$t('about.text153')}}</h4>  
                                <p>{{$t('about.text184')}}</p>     
                            </div> 
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body text-center bg-white p-3 h-100">
                                <span class="d-block mb-4"><v-lazy-image class="d-block mx-auto h-70" src="/assets/images/technical-services.svg" :alt="$t('about.text417')" :title="$t('about.text418')" /></span>
                                <h4 class="bold">{{$t('about.text185')}}</h4>  
                                <p>{{$t('about.text186')}}</p>     
                            </div> 
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body text-center bg-white p-3 h-100">
                                <span class="d-block mb-4"><v-lazy-image class="d-block mx-auto h-70" src="/assets/images/supportIcon.svg" :alt="$t('about.text419')" :title="$t('about.text420')" /></span>
                                <h4 class="bold">{{$t('about.text187')}}</h4> 
                                <p>{{$t('about.text188')}}</p>     
                            </div> 
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space darkZulutrade">
                <div class="container">
                    <div class="row row-cols-5">
                        <div class="col-12 mb-4 mb-md-4 text-center">
                            <h2 class="text-white">{{$t('about.text161')}}</h2>
                        </div>
                        <div class="col-12">
                            <v-lazy-image src="/assets/images/system-overview.svg" :alt="$t('about.text421')" />
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space bg-white forexTool-page">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-12 mb-md-4 text-center">
                                <h2>{{$t('about.text189')}}</h2>
                                <p>{{$t('about.text190')}}</p>
                            </div>
                            <div class="col-12 col-md-12 mb-4">
                                <!-- <form class="contactUSForm p-3 p-lg-5">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>First name</label>
                                                <input type="text" class="form-control" placeholder="First name" name="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Last name</label>
                                                <input type="text" class="form-control" placeholder="Last name" name="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input type="text" class="form-control" placeholder="Email" name="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Job Title</label>
                                                <input type="text" class="form-control" placeholder="Job Title" name="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Company</label>
                                                <input type="text" class="form-control" placeholder="Company" name="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Country of location</label>
                                                <select class="form-select">
                                                    <option>Afghanistan</option>
                                                    <option>Algeria</option>
                                                    <option>American Samoa</option>
                                                    <option>Belize</option>
                                                    <option>China</option>
                                                    <option>Faroe Islands</option>
                                                    <option>India</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="d-flex align-items-center">
                                                <div class="form-group me-2">
                                                    <label>Country</label>
                                                    <select class="form-select">
                                                        <option>India(+91)</option>
                                                        <option>India(+91)</option>
                                                        <option>India(+91)</option>
                                                        <option>India(+91)</option>
                                                        <option>India(+91)</option>
                                                        <option>India(+91)</option>
                                                        <option>India(+91)</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label>Phone</label>
                                                    <input type="number" class="form-control" placeholder="Phone Number" name="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Relationship Type</label>
                                                <select class="form-select">
                                                    <option>Standard White Label</option>
                                                    <option>ZTP Platform</option>
                                                    <option>Complete Solution</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Details (optional)</label>
                                                <textarea type="text" class="form-control textarea" placeholder="Enter your Message" name=""></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Why did you choose to offer ZuluTrade to your clients?</label>
                                                <select class="form-select">
                                                    <option>Choose your answer</option>
                                                    <option>Convert dormant accounts</option>
                                                    <option>Value-add to active users new to trading</option>
                                                    <option>New client acquisition</option>
                                                    <option>Client retention</option>
                                                    <option>To increase volume per account</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                        </div>
                                         <div class="col-12">
                                            <div class="form-group">
                                                <label>Which of the following value-adds would most encourage you to further promote our platform?</label>
                                                <select class="form-select">
                                                    <option>Choose your answer </option>
                                                    <option>Paid incentive scheme</option>
                                                    <option>More seamless user registration / connection process</option>
                                                    <option>Higher profitability of accounts</option>
                                                    <option>High-quality daily leads</option>
                                                    <option>Thought leadership and training materials for client and your sales support</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form> -->
                                <div class="col-12 text-center">
                                    <a href="mailto:partnerships@zulutrade.com" class="button fillBtn zulu_btn px-md-5">{{$t('about.text189')}}</a>
                                    <!-- <div class="text-center mt-3">
                                        <a target="_blank" class="secondary" href="https://www.zulutrade.com/partners/login">Download</a> our full presentation!
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </div>
</template>